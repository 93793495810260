import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'About us',
  introTitle: 'We are technology experts, real partners and your powerhouse for digital success',
  introInfo: 'freshcells systems engineering GmbH is a software and consulting company from Düsseldorf. We design, develop and maintain professional and complex software solutions.{br}{br}Since 2005 we have been supporting major customers with the approach of systems engineering. "Systems engineering promotes a holistic view of technical, organizational and human aspects in order to design complex systems efficiently and reliably." - Tom Hülser CEO{br}{br}We are partners in the conception and continuous development of complex systems, cross-platform, stable and flexible. A holistic technological approach combined with the pursuit of real added value is our formula for success. {br}{br}In our multicultural team, software architects, UI/UX experts, web designers, application programmers, database programmers, strategy consultants, project managers, technical project managers, scrum masters, product owners and analysts with profound expert knowledge and many years of experience work together.{br}{br} We believe: Excellent and absolutely honest consulting is the basis for successful projects. That is why many well-known companies from the tourism, media, consumer goods, chemical and pharmaceutical industries are among our long-standing clients.',
  btn: 'Get in touch'
})
